<template>
  <div :class="classes">
    <svg
      :class="[$style.icon, classIcon]"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64356 1.45008L4.37127 2.53546C1.95708 3.33621 0.64914 5.94244 1.44989 8.35663L2.53527 11.6289C3.33602 14.0431 5.94225 15.3511 8.35643 14.5503L11.6287 13.4649C14.0429 12.6642 15.3509 10.0579 14.5501 7.64375L13.4647 4.37147C12.664 1.95728 10.0578 0.649329 7.64356 1.45008Z"
        fill="currentColor"
      />
      <path
        d="M11.1016 5L5.85679 5.00155L5.37963 6.47097H9.04738L4.32462 9.65613C3.96289 9.90013 3.8924 10.4036 4.1728 10.7375C4.41448 11.0256 4.83509 11.0845 5.14648 10.8746L9.91261 7.65998L8.86612 10.88H10.54L12.0335 6.28274C12.2395 5.64989 11.767 5 11.1016 5V5Z"
        fill="white"
      />
    </svg>
    <span :class="$style.valueWrapper">
      <span
        :class="[
          $style.value,
          classValue,
          { [$style.isMinor]: isMinor },
          { [$style.isDiscounted]: isDiscounted }
        ]"
      >
        <slot name="before" />
        {{ formattedValue }}
        <span v-if="showName"> {{ code.toUpperCase() }}</span>
        <slot name="after" />
      </span>
    </span>
  </div>
</template>

<script>
import number from '../../../utils/number'
import appConfig from '../../../app.config'

export default {
  name: 'UiCoin',
  props: {
    showName: {
      type: Boolean,
      default: false
    },
    isMinor: {
      type: Boolean,
      default: false
    },
    isDiscounted: {
      type: Boolean,
      default: false
    },
    /**
     * Coin code
     * @values pari, lc
     */
    code: {
      type: String,
      default: appConfig.mainCoinCode,
      transform: v => (v ? v.toUpperCase() : '')
    },
    /**
     * Coin amount
     */
    value: {
      type: [String, Number],
      default: 0
    },
    /**
     * The size of the coin
     * @values small, medium
     */
    size: {
      type: String,
      default: 'small',
      validate: v =>
        ['custom', 'xsmall', 'small', 'medium', 'large'].includes(v)
    },
    classIcon: {
      type: String,
      default: ''
    },
    classValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes() {
      return [
        this.$style.coin,
        this.code && [this.$style[this.code.toUpperCase()]],
        this.value >= 1000000 && this.$style.bignumber,
        [this.$style[this.size]]
      ]
    },
    formattedValue() {
      return number(this.value)
    }
  }
}
</script>

<style lang="scss" module>
.coin {
  display: flex;
  align-items: center;
}
.value {
  font-family: var(--primary-font), sans-serif;
  font-style: var(--primary-font-style);
  font-weight: 700;
  white-space: nowrap;

  &.isMinor {
    font-weight: 400;
  }

  &.isDiscounted {
    text-decoration: line-through;
  }
}

.xsmall {
  .icon {
    width: 1.4em;
    height: 1.4em;
    margin-right: 0.4em;
  }
  .value {
    font-size: 1.4em;
    line-height: 100%;
  }
}
.small {
  .icon {
    width: 1.6em;
    height: 1.6em;
    margin-right: 0.5em;
  }
  .value {
    font-size: 2em;
  }
}
.medium {
  .icon {
    width: 2.6em;
    height: 2.6em;
    margin-right: 0.4em;
  }
  .value {
    font-size: 3.2em;
    line-height: 131%;
  }
}
.large {
  .icon {
    width: 3.8em;
    height: 3.8em;
    margin-right: 0.4em;
    @include down(sm) {
      width: 2.6em;
      height: 2.6em;
    }
  }
  .value {
    font-size: 4.8em;
    line-height: 104%;
    @include down(sm) {
      font-size: 3.2em;
      line-height: 131%;
    }
  }
}
.custom {
  .icon {
    margin-right: 0.2em;
  }
}
.bignumber {
  .valueWrapper {
    font-size: 0.7em;
  }
}
.PARI .icon,
.GC .icon,
.DP .icon {
  color: #6a34b5;
}
.LC .icon {
  color: #00c7b1;
}
</style>
